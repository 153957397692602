import React, { ReactElement, useState, useEffect } from 'react'
import { toLower, replace } from 'lodash'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'

import { find, orderBy } from 'lodash'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const SEO = loadable(() => import('../../components/SEO/SEO'))
const OurPortfolio = loadable(() => import('../../components/Portfolio'))

import './Portfolio.scss'

const getFilterMenu = (propertyType: string) => {
  switch (propertyType) {
    case 'established-communities':
      return 'Townhouse'
    case 'leisure':
      return 'leisure'
    case 'work-environments':
      return 'office'
    default:
      return 'Condominium'
  }
}

const sortByDatePublished = (filteredProperties: any, contents: any) => {
  const propertiesWithDate = filteredProperties.map((property: any) => {
    const content = find(contents, { slug: property.slug })
    property['datePublished'] = content ? content.datePublished : ''
    return property
  })

  return orderBy(
    propertiesWithDate,
    [
      (property) => {
        return new Date(property.datePublished !== '' ? property.datePublished : null)
      }
    ],
    ['desc']
  )
}

const filterProperty = (properties: any, contents: any) => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname.split('/') : ''
  const filteredProperties = properties.filter(
    ({ propertyDetails, featured }: any) => featured && toLower(propertyDetails?.propertyType) === toLower(getFilterMenu(pathname[2]))
  )

  if (toLower(pathname[2]) === 'established-communities') {
    return sortByDatePublished(filteredProperties, contents)
  }

  return filteredProperties
}

const optimizedProperties = (properties: any, optimizedImgs: any) => {
  const images = optimizedImgs?.allSitePage?.edges
  const result = properties?.map((property: any) => {
    const propertyPath = `/our-portfolio/${property?.slug}/`
    const imageNode = images?.find((img: any) => img?.node?.path === propertyPath)?.node?.optimizedPreviewImg
    property.optimizedPreviewImg = imageNode
    return property
  })

  return result
}

const OurPortfolioTemp = ({ pageContext: { properties, contents, metaData }, location }: any): ReactElement => {
  const loc = new URLSearchParams(location.search).get('location')
  const type = new URLSearchParams(location.search).get('property-type')

  const [propertiesList, setPropertiesList] = useState<any>([])
  const [filters, setFilter] = useState<any>({ location: loc || '', category: '' })
  const [search, setSearch] = useState<string>('')
  const [selectedProject, setSelectedProject] = useState<any>(null)
  const [projectList, setProjectList] = useState<any>([])
  const [showSearch, setShowSearch] = useState<any>(false)

  const optimizedImgs = useStaticQuery(graphql`
    query alpProperties {
      allSitePage(filter: { context: { type: { eq: "SingleProperty" }, category: { eq: "properties" } } }) {
        edges {
          node {
            optimizedPreviewImg {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            path
          }
        }
      }
    }
  `)
  const alpProperties = optimizedProperties(properties, optimizedImgs)

  const setContent = () => {
    const { location, category } = filters
    let tempData = alpProperties
    if (filters && location) {
      tempData = tempData.filter(({ propertyDetails }: any) => {
        const itemLocation = propertyDetails?.location
        return toLower(itemLocation) === toLower(location)
      })
    }
    if (type) {
      tempData = tempData.filter(({ propertyDetails }: any) => {
        const itemType = propertyDetails?.propertyType
        return toLower(itemType) === toLower(type)
      })
    }
    if (category) {
      tempData = tempData.filter(({ propertyDetails }: any) => toLower(propertyDetails?.subCategory) === toLower(category))
    }
    setPropertiesList(filterProperty(tempData, contents))
  }

  useEffect(() => {
    setContent()
  }, [filters])

  useEffect(() => {
    setContent()
    setProjectList(alpProperties)
  }, [])

  useEffect(() => {
    const search = typeof window && window.location.search
    if (search) {
      const selectedProperty = properties?.filter((property: any) => property.slug === replace(search, '?', ''))[0]

      if (!selectedProperty) {
        return
      }

      handleOnClickProject(selectedProperty)
    }
  }, [typeof window !== 'undefined' ? window.location.search : ''])

  const handleLocation = (value: any) => {
    setFilter({ ...filters, location: value })
  }

  const handleCategory = (value: any) => {
    setFilter({ ...filters, category: value })
  }

  const handleProject = (value: any) => {
    let results = alpProperties.filter(({ name }: any) => toLower(name).indexOf(toLower(value)) != -1)
    setProjectList(results)
  }

  const handleSearch = (value: any) => {
    if (!value) {
      setPropertiesList(alpProperties)
      setSearch('')
    } else {
      const results = alpProperties.filter((prop: any) => prop?.id === value)
      setSelectedProject(results[0])
      setSearch(results.length > 0 ? results[0]?.projectName : '')

      navigate(`/our-portfolio/${results[0]?.slug}/`)
    }
  }

  const handleOnClickProject = (project: any) => {
    setSelectedProject(project)

    window.history.pushState('', '', `/our-portfolio/${project.slug}/`)
  }

  const toggleSearchListener = (event: any) => {
    setShowSearch(event)
  }

  const metaImg = () => {
    const overview = propertiesList[0]?.propertyUnits[0]?.overviewDetails
    const featured = overview?.imagesGallery?.filter((img: any) => img.featured)
    return featured ? featured[0]?.image : ''
  }

  return (
    <Layout
      {...{
        props: {
          query: loc || type,
          handleSearch,
          handleLocation,
          handleCategory,
          filters,
          search,
          setSearch,
          projectList,
          handleProject,
          portfolio: false,
          toggleSearchListener,
          filteredProperties: propertiesList,
          layoutStyle: 'portfolio-layout'
        }
      }}
    >
      <SEO
        title={metaData?.title ? metaData?.title : 'Our Portfolio - Ayala Land Premier'}
        description={metaData?.description}
        image={metaImg()}
      />
      <div className={`portfolio-page ${showSearch ? 'portfolio-page--toggle-search' : ''}`}>
        <h1 style={{ display: 'none' }}>Luxury Living Spaces</h1>
        <OurPortfolio
          {...{
            propertiesList,
            alpProperties,
            selectedProject,
            handleOnClickProject,
            showSearch,
            filters
          }}
        />
      </div>
    </Layout>
  )
}

export default OurPortfolioTemp
